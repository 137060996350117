html {
  font-size: 62.5%;
}

body {
  margin: 0;
  background-color: #f3f3f3; /* TODO */
  font-family: Inter, sans-serif;
}

* {
  font-family: Inter, sans-serif;
  margin: initial;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*Universal Style Notes*/
@font-face {
  font-family: Inter;
  font-display: swap;
  src: url('./assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
}
@font-face {
  font-family: Inter;
  font-weight: bold;
  font-display: swap;
  src: url('./assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
}
@font-face {
  font-family: InterLight;
  font-display: swap;
  src: url('./assets/fonts/Inter/Inter-Light.ttf') format('truetype');
}
@font-face {
  font-family: InterSemiBold;
  font-weight: 600;
  font-display: swap;
  src: url('./assets/fonts/Inter/Inter-SemiBold.ttf') format('truetype');
}
